import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import styled from '@emotion/styled';
import logo from '../images//logo.jpeg'

const Navbar = styled(AppBar)`
margin-bottom: 16px;
background-color: white
`;

const TopBar = () => {
  return (
    <Navbar position="fixed">
        <Toolbar>
            <Box
                component="img"
                sx={{
                    height: 100,
                }}
                alt="logo"
                src={logo}
            />
        </Toolbar>
    </Navbar>
  );
};

export default TopBar;