import React from 'react';
import { Typography, Container, Grid, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styled from '@emotion/styled';
import img from '../images//img.jpg'
import Form from './Form';

const HeroSection = styled(Box)`
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  height: 100vh;
`;

const Section = styled(Box)`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  @media (max-width: 600px) {
    padding: 60px 0;
  }
`;

const Footer = styled(Box)`
  padding: 32px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: black;
  color: white;
`;

const Column = styled(Grid)`
  align-items: center;
  text-align: center;
`;

const Img = styled(Grid)`
  background-image: url(${img});
  background-size: fit;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const LandingPage = () => {
  return (
      <Container maxWidth={false} disableGutters>
        <HeroSection>
          <Grid sx={{
            bgcolor: 'white',
            width: { xs: "90%", md: "75%" },
            padding: '35px',
            borderRadius: '50px'
          }}>
            <Typography sx={{ typography: { md: 'h5', xs: 'subtitle1' } }}>Establishing the Divine Truth revealed by Almighty Allah</Typography>
            <Typography  sx={{ typography: { md: 'body1', xs: 'body2' } }}>
              So direct your face toward the religion, inclining to truth. [Adhere to] the fitrah of Allah upon which He has created [all] people. No change should there be in the creation of Allah. That is the correct religion, but most of the people do not know.
            </Typography>
            <Typography sx={{ typography: { md: 'body1', xs: 'body2' } }}>
              1پس (اے نبیؐ اور نبیؐ کے پیروو) یک سُو ہو کر اپنا رخ اس دین2 کی سمت میں جمادو،3 قائم ہو جاؤ اس فطرت پر جس پر اللہ تعالیٰ نےانسانوں کو پیدا کیا ہے،4 اللہ کی بنائی ہوئی ساخت بدلی نہیں جا سکتی،5 یہی بالکل راست اور درست دین ہے،6 مگر اکثر لوگ جانتے نہیں ہیں
            </Typography>
            <Typography variant='overline'>
              اللہ ہمارا حامی و ناصر ہو۔
            </Typography>
          </Grid>
        </HeroSection>

        <Section>
          <Grid container>
            <Column item xs={12} md={6}>
              <Form/>
            </Column>
            <Img item xs={12} md={6}/>
          </Grid>
        </Section>

        <Footer>
          <IconButton color='secondary' target='_blank' href='https://www.facebook.com/profile.php?id=61561148197318'><FacebookIcon/></IconButton>
          <IconButton color='secondary' target='_blank' href='https://www.instagram.com/fitrahrenaissance'><InstagramIcon/></IconButton>
          <IconButton color='secondary' target='_blank' href='https://wa.me/+923292324652'><WhatsAppIcon/></IconButton>
        </Footer>
      </Container>
  );
};

export default LandingPage;