import './App.css';
import TopBar from './components/TopBar';
import LandingPage from './components/LandingPage';
import styled from '@emotion/styled';
import bgimage from './images/bg.jpg'
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    },
  }
});

const Root = styled.div`
flex-grow: 1;
background-image: url(${bgimage});
background-size: cover;
background-attachment: fixed;
`;


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Root>
        <TopBar />
        <LandingPage />
      </Root>
    </ThemeProvider>
  );
}

export default App;
